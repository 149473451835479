<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { initFbsdk } from "@/core/config/facebook.js";
import { API_URL, MEDIA_URL } from "@/core/api";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "login-1",
  data() {
    return {
      jobSeekerSignUp: false,
      recruiterSignUp: true,
      agencySignUp: false,
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      cities: [],
      apiUrl: API_URL,
      search_text: "",
      selected_cities: [],
      novalidate: "",
      showPassword: true,
      radio_btn: false,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),

    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },

  mounted() {
    if (window.location.href.indexOf("register") !== -1) {
      this.showForm("signup");
    }

    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Passwords do not match",
            },
          },
        },

        agree: {
          validators: {
            notEmpty: {
              message: "Please accept terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name)
        // "animate__animated animate__backInUp"
      );
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },
    radio_btn_fun() {
      this.radio_btn = true;
      // console.log("rrrrrrrr", this.radio_btn);
    },
    onSubmitRegister() {
      // this.fv1.on("core.form.valid", () => {
      this.fv1.validate();
      if (this.radio_btn == true) {
        this.fv1.validate().then((res) => {
          if (res == "Valid") {
            const firstName = this.$refs.firstName.value;
            const lastName = this.$refs.lastName.value;
            const email = this.$refs.remail.value;
            const password = this.$refs.rpassword.value;
            // const compnay_name = this.$refs.compnay_name.value;
            const compnay_name =
              this.recruiterSignUp == true || this.agencySignUp == true
                ? this.$refs.compnay_name.value
                : null;

            // clear existing errors
            this.$store.dispatch(LOGOUT);

            // set spinner to submit button
            const submitButton = this.$refs["kt_login_signup_submit"];
            submitButton.classList.add(
              "spinner",
              "spinner-light",
              "spinner-right"
            );

            // dummy delay
            setTimeout(() => {
              // send register request
              console.log("Register page log");
              this.$store.dispatch(REGISTER, {
                email: email,
                password: password,
                name: firstName + " " + lastName,
                compnay_name: compnay_name,
                role_id:
                  this.recruiterSignUp == true
                    ? "2"
                    : this.agencySignUp == true
                    ? "6"
                    : "1",
              });
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            }, 2000);
          } else {
            Swal.fire({
              title: "",
              text: "Please, provide correct data!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
      } else {
        Swal.fire({
          title: "",
          text: "Please Select Direct employer ",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    },

    AuthProvider(provider) {
      var self = this;

      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {});
    },
  },
};
</script>
<template>
  <div class="mt-17">
    <div>
      <!-- Navbar STart -->
      <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === true }"
      >
        <div class="container">
          <!-- Logo container-->
          <div>
            <router-link class="logo" to="/" v-if="navLight !== true">
              <img src="/images/logo-dark.svg" height="50" alt="" />
            </router-link>
            <router-link class="logo" to="/" v-else>
              <img
                src="/images/logo-dark.svg"
                class="l-dark"
                height="50"
                alt=""
              />
              <img
                src="/images/logo-dark.svg"
                class="l-light"
                height="24"
                alt=""
              />
            </router-link>
          </div>
          <div class="buy-button" style="width: 28% text-align: end;">
            <a
              v-if="auth"
              class="btn auth_btn_ch2 dropdown"
              :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
              >My Account
              <div class="dropdown-content">
                <router-link
                  to="/dashboard"
                  class="btn auth_btn_ch3"
                  style="
                    margin-left: 3%;
                    background: transparent !important;
                    color: #000000 !important;
                  "
                  >Dashboard</router-link
                >
                <a
                  @click="onLogout"
                  class="btn auth_btn_ch3"
                  style="
                    margin-left: 3%;
                    background: transparent !important;
                    color: #000000 !important;
                  "
                  >Logout</a
                >
              </div>
            </a>
            <a
              v-if="!auth"
              href="/login"
              class="btn auth_btn_ch1"
              :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
              >Login</a
            >
            <a
              v-if="!auth"
              href="/recruiter-registeration"
              class="btn auth_btn_ch2"
              :class="{
                'btn-light': navLight === true,
                'btn-warning': navLight !== true,
              }"
              style="margin-left: 3%"
              >Register</a
            >
            <a
              v-if="!auth || recruiter"
              href="/recruit-top-talent-in-pakistan"
              class="btn auth_btn_ch3"
              style="margin-left: 3%; background: #a9250f; color: white"
              >Recruiters &nbsp;&nbsp;<i
                class="fa fa-caret-right"
                style="color: white"
              ></i
            ></a>

            <a
              v-if="auth && jobSeeker"
              href="/dashboard"
              class="btn auth_btn_ch4"
              style="margin-left: 3%; background: #a9250f; color: white"
            >
              Job Seekers &nbsp;&nbsp;<i
                class="fa fa-caret-right"
                style="color: white"
              ></i
            ></a>
          </div>

          <!--end login button-->
          <!-- End Logo container-->
          <div class="menu-extras">
            <div class="menu-item">
              <!-- Mobile menu toggle-->
              <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
              >
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              <!-- End mobile menu toggle-->
            </div>
          </div>

          <div id="navigation">
            <!-- Navigation Menu-->
            <ul
              class="navigation-menu"
              :class="{ 'nav-light': navLight === true }"
            >
              <li>
                <router-link to="/" class="side-nav-link-ref">Home</router-link>
              </li>
              <li>
                <router-link to="/jobs-in-pakistan" class="side-nav-link-ref"
                  >Jobs</router-link
                >
              </li>

              <li>
                <router-link to="/career-resources" class="side-nav-link-ref"
                  >Career Resources</router-link
                >
              </li>
              <div class="ml-10 mb-10 hide_on_dectop_ch">
                <a
                  v-if="auth"
                  class="btn auth_btn_ch2 dropdown"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-primary': navLight !== true,
                  }"
                  >My Account
                  <div class="dropdown-content">
                    <router-link
                      to="/dashboard"
                      class="btn auth_btn_ch3"
                      style="
                        margin-left: 3%;
                        background: transparent !important;
                        color: #000000 !important;
                      "
                      >Dashboard</router-link
                    >
                    <a
                      @click="onLogout"
                      class="btn auth_btn_ch3"
                      style="
                        margin-left: 3%;
                        background: transparent !important;
                        color: #000000 !important;
                      "
                      >Logout</a
                    >
                  </div>
                </a>
                <a
                  v-if="!auth"
                  href="/login"
                  class="btn auth_btn_ch1"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-primary': navLight !== true,
                  }"
                  >Login</a
                >
                <a
                  v-if="!auth"
                  href="/recruiter-registeration"
                  class="btn auth_btn_ch2"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-warning': navLight !== true,
                  }"
                  style="margin-left: 3%"
                  >Register</a
                >
                <a
                  v-if="!auth || recruiter"
                  href="/recruit-top-talent-in-pakistan"
                  class="btn auth_btn_ch3"
                  style="margin-left: 3%; background: #a9250f; color: white"
                  >Recruiters &nbsp;&nbsp;<i
                    class="fa fa-caret-right"
                    style="color: white"
                  ></i
                ></a>

                <a
                  v-if="auth && jobSeeker"
                  href="/dashboard"
                  class="btn auth_btn_ch4"
                  style="margin-left: 3%; background: #a9250f; color: white"
                >
                  Job Seekers &nbsp;&nbsp;<i
                    class="fa fa-caret-right"
                    style="color: white"
                  ></i
                ></a>
              </div>
            </ul>
            <!--end navigation menu-->
            <!-- <div class="buy-menu-btn d-none">
            <a href="#" class="btn btn-primary">Buy Now</a>
          </div> -->
            <!--end login button-->
          </div>
          <!--end navigation-->
        </div>
        <!--end container-->
      </header>
      <!--end header-->
      <!-- Navbar End -->
    </div>
    <div class="rigister_image_bg">
      <div class="container" style="margin: 0 auto">
        <div
          class="login login-1"
          :class="{
            'login-signin-on': this.state == 'signin',
            'login-signup-on': this.state == 'signup',
            'login-forgot-on': this.state == 'forgot',
          }"
          id="kt_login"
        >
          <div class="row align-items-center">
            <!--begin::Aside-->
            <div class="col-lg-4 auth_heading">
              Enter into the world of
              <span class="font-weight-bolder"> opportunities</span>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div class="col-lg-5 row m-0 col-md-6 bg-white p-0 Small">
              <div class="col-12 mb-5 row m-0 ch_rej_btn_h1 p-0">
                <h4 class="col-6 row m-0 text-center job_sekker_btn4">
                  <a href="/recruiter-registeration">
                    Register to recruit <br />
                    talent</a
                  >
                </h4>
                <h2 class="col-6 row m-0 job_sekker_btn3">
                  <a href="/job-seeker-register"
                    >Register as a <br />
                    job Seeker</a
                  >
                </h2>
                <!-- <p>Register as a recruiter</p> -->
              </div>
              <div class="px-10 pb-9">
                <div class="">
                  <!--begin::Signup-->
                  <div class="login-form login-signup">
                    <form
                      class="form text-center"
                      novalidate="novalidate"
                      id="kt_login_signup_form"
                      @submit.stop.prevent="onSubmitRegister()"
                    >
                      <div class="form-check form-check-inline text-muted">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="direct_recruitment"
                          id="direct_employer"
                          @click="radio_btn_fun()"
                          @change="
                            () => {
                              recruiterSignUp = true;
                              jobSeekerSignUp = false;
                              agencySignUp = false;
                              showForm('signup');
                            }
                          "
                        />
                        <label class="form-check-label" for="direct_employer">
                          Direct employer
                        </label>
                      </div>
                      <div class="form-check form-check-inline mb-5 d-none">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="direct_recruitment"
                          id="recruitment_agency"
                          @click="radio_btn_fun()"
                          @change="
                            () => {
                              agencySignUp = false;
                              recruiterSignUp = true;
                              jobSeekerSignUp = false;
                              showForm('signup');
                            }
                          "
                        />
                        <label
                          class="form-check-label text-muted"
                          for="recruitment_agency"
                        >
                          Recruitment agency
                        </label>
                      </div>

                      <div class="form-group auth_inputs_img position-relative">
                        <img
                          src="/images/login_contact/usernameemail.svg"
                          alt=""
                        />
                        <input
                          class="form-control fieldch"
                          type="text"
                          placeholder="First name"
                          name="firstName"
                          ref="firstName"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img
                          src="/images/login_contact/usernameemail.svg"
                          alt=""
                        />
                        <input
                          class="form-control fieldch"
                          type="text"
                          placeholder="Last name"
                          name="lastName"
                          ref="lastName"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        v-if="recruiterSignUp"
                        class="form-group auth_inputs_img position-relative"
                      >
                        <img src="/images/login_contact/co.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          type="text"
                          placeholder="Company name"
                          name="compnay_name"
                          ref="compnay_name"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        v-if="agencySignUp"
                        class="form-group auth_inputs_img position-relative"
                      >
                        <img src="/images/login_contact/co.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          type="text"
                          placeholder="Company name"
                          name="compnay_name"
                          ref="compnay_name"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/email.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          ref="remail"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/password.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          placeholder="Enter Password"
                          name="password"
                          ref="rpassword"
                          autocomplete="off"
                          :type="showPassword === false ? 'text' : 'password'"
                        />
                        <span
                          @click="toggleShow"
                          class="icon is-small is-right password_show_ch"
                        >
                          <i
                            :class="
                              showPassword === false
                                ? 'fas fa-eye-slash'
                                : 'fas fa-eye'
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/password.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          placeholder="Retype Password"
                          name="cpassword"
                          ref="cpassword"
                          autocomplete="off"
                          :type="showPassword === false ? 'text' : 'password'"
                        />
                        <span
                          @click="toggleShow"
                          class="icon is-small is-right password_show_ch"
                        >
                          <i
                            :class="
                              showPassword === false
                                ? 'fas fa-eye-slash'
                                : 'fas fa-eye'
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="form-group mb-0">
                        <label
                          class="checkbox mb-0"
                          style="color: #3f4254 !important"
                        >
                          <p class="ml-2">
                            <input
                              class="mr-1 align-middle"
                              type="checkbox"
                              name="agree"
                            />
                            I have read and accept the
                            <a
                              href="/terms-and-conditions"
                              class="ml-1 text-primary font-weight-bolder"
                              >terms and conditions</a
                            >
                          </p>
                        </label>
                      </div>
                      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                        <!-- @click="onSubmitRegister()" -->
                        <button
                          ref="kt_login_signup_submit"
                          class="btn submitBnt"
                          style="width: 150px"
                        >
                          SIGN UP
                        </button>
                      </div>
                    </form>
                  </div>

                  <div class="hr_blue"></div>
                  <div
                    class="text-muted text-center"
                    style="font-size: 13px !important"
                  >
                    Already have an account?
                    <a
                      id=""
                      class="text-primary font-weight-bolder"
                      href="/login"
                      >Login</a
                    >
                  </div>
                  <!--end::Signup-->

                  <!--begin::Forgot-->
                  <div class="login-form login-forgot">
                    <!--begin::Form-->
                    <form
                      class="form"
                      novalidate="novalidate"
                      id="kt_login_forgot_form"
                      ref="kt_login_forgot_form"
                    >
                      <div class="">
                        <h1 class="auth_title">Forgotten Password</h1>
                        <p
                          class="text-muted text-center"
                          style="font-size: 12px"
                        >
                          Enter your email to reset your password
                        </p>
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/email.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          type="email"
                          placeholder="Enter Email Adserss"
                          name="email"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group d-flex flex-wrap pb-lg-0">
                        <button
                          type="button"
                          id="kt_login_forgot_submit"
                          class="btn submitBnt"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                  <!--end::Forgot-->
                </div>
                <!--begin::Content footer-->
                <div class="text-center pt-8">
                  <a
                    href="/terms-and-conditions"
                    class="font-weight-bold font-size-h6"
                    >Terms</a
                  >
                  <a
                    href="/recruit-top-talent-in-pakistan"
                    class="ml-10 font-weight-bold font-size-h6"
                    >Plans</a
                  >
                  <a
                    href="/contact-us"
                    class="ml-10 font-weight-bold font-size-h6"
                    >Contact Us</a
                  >
                </div>
                <!--end::Content footer-->
              </div>
            </div>
            <!--end::Content-->
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Footer Start -->
      <footer class="footer mt-15">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 class="text-light footer-head">Jobs by city</h4>
              <div style="display: flex">
                <ul class="list-unstyled footer-list mt-4 small-text-mb">
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Lahore"> Lahore</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Faisalabad"> Faisalabad</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Rawalpindi"> Rawalpindi</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Karachi"> Karachi</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Hyderabad"> Hyderabad</a>
                    </span>
                  </li>
                </ul>
                <ul class="list-unstyled footer-list mt-4 small-text-mb ml-20">
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Peshawar"> Peshawar</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Quetta"> Quetta</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sialkot"> Sialkot</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sheikhupurah"> Sheikhupurah</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sargodha"> Sargodha</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div
              class="
                col-lg-2 col-md-4 col-6
                mt-6 mt-sm-0
                pt-2 pt-sm-0
                small-text-mb
              "
              style="padding-right: 2px"
            >
              <h4 class="text-light footer-head">Employers</h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a href="/login" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                  >
                </li>
                <li>
                  <a href="/recruiter-registeration" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Create
                    account</a
                  >
                </li>
                <li>
                  <router-link to="/recruiter/jobs/create" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Post a
                    job</router-link
                  >
                </li>
                <li v-if="auth && recruiter">
                  <router-link to="/dashboard" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Search
                    candidates</router-link
                  >
                </li>
                <li>
                  <router-link to="/contact-us" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    Contact</router-link
                  >
                </li>
              </ul>
            </div>
            <!--end col-->

            <div
              class="
                col-lg-3 col-md-4 col-6
                mt-6 mt-sm-0
                pt-2 pt-sm-0
                small-text-mb
              "
            >
              <h4 class="text-light footer-head">Job seekers</h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a href="/login" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                  >
                </li>
                <li>
                  <a href="/job-seeker-register" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Create
                    account</a
                  >
                </li>
                <li>
                  <router-link to="/contact-us" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    Contact</router-link
                  >
                </li>
                <li>
                  <router-link to="/recruiter-directory" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Recruiter
                    directory</router-link
                  >
                </li>
              </ul>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 class="text-light footer-head">Newsletter</h4>
              <p class="mt-4 small-text-mb">
                Sign up for latest news and industry updates
              </p>
              <form>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="foot-subscribe form-group position-relative">
                      <!-- <label
                        >Write your email
                        <span class="text-danger">*</span></label
                      > -->
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control pl-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <input
                      type="submit"
                      id="submitsubscribe"
                      name="send"
                      class="btn btn-soft-primary btn-block"
                      value="Subscribe"
                    />
                  </div>
                </div>
              </form>
              <ul class="list-unstyled social-icon social mb-0 mt-2 text-start">
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <img
                      src="/images/login_contact/Facebook.svg"
                      height="12"
                      alt=""
                    />
                    <!-- <facebook-icon class="fea icon-sm fea-social"></facebook-icon> -->
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a
                    href="https://www.instagram.com/wwwjob4upk/"
                    class="rounded"
                  >
                    <img
                      src="/images/login_contact/Instagram.svg"
                      height="12"
                      alt=""
                    />
                    <!-- <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon> -->
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a href="javascript:void(0)" class="rounded">
                    <img
                      src="/images/login_contact/Twitter.svg"
                      height="12"
                      alt=""
                    />
                    <!-- <twitter-icon class="fea icon-sm fea-social"></twitter-icon> -->
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a href="#" class="rounded">
                    <img
                      src="/images/Whatsapp.svg"
                      width="15"
                      height="15"
                      alt="..."
                      class=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </footer>
      <!--end footer-->
      <footer class="footer footer-bar">
        <div class="container text-center">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <div class="text-sm-left">
                <p class="mb-0">© job4u.pk 2022.</p>
              </div>
            </div>
            <div class="col-sm-6 col-12 row m-0">
              <div class="text-sm-right col-lg-8 col-5 p-0">
                <a href="/privacy-policy" class="ml-2 text-primary"
                  >Privacy Policy</a
                >
              </div>
              <div class="text-sm-right col-lg-4 col-7 p-0">
                <a href="/terms-and-conditions" class="ml-2 text-primary"
                  >Terms and Conditions</a
                >
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </footer>
      <!--end footer-->
    </div>
  </div>
</template>
<style scoped>
footer span,
footer a,
footer p,
footer h4,
footer label {
  color: white !important;
}
</style>